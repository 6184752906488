/* eslint-disable */
export default {
  "types": {
    "default": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360]
      },
      "sizes": []
    },
    "cms-two-column-no-ratio": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360]
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "50vw"
        }
      ]
    },
    "cms-three-column-no-ratio": {
      "desktop": {
        "srcSetWidths": [850,1100,1360]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360]
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "33vw"
        }
      ]
    },
    "cms-card-carousel": {
      "desktop": {
        "srcSetWidths": [850,1100,1360]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360]
      },
      "sizes": [
        {
          "767": "95vw"
        },
        {
          "1023": "46vw"
        },
        {
          "4999": "30vw"
        }
      ]
    },
    "cms-two-column": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680],
        "aspectRatio": "widescreen"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "widescreen"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "50vw"
        }
      ]
    },
    "cms-three-column": {
      "desktop": {
        "srcSetWidths": [850,1100,1360],
        "aspectRatio": "widescreen-square"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "widescreen-square"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "33vw"
        }
      ]
    },
    "cms-four-column": {
      "desktop": {
        "srcSetWidths": [850,1100,1360],
        "aspectRatio": "vertical-square"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "vertical-square"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "25vw"
        }
      ]
    },
    "cms-triple-teaser-big": {
      "desktop": {
        "srcSetWidths": [850,1100,1360],
        "aspectRatio": "vertical-square"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "vertical-square"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "1023": "70vw"
        },
        {
          "1349": "64vw"
        },
        {
          "1559": "61vw"
        },
        {
          "4999": "56vw"
        }
      ]
    },
    "cms-triple-teaser-small": {
      "desktop": {
        "srcSetWidths": [850,1100,1360],
        "aspectRatio": "widescreen"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "widescreen"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "1023": "50vw"
        },
        {
          "1349": "30vw"
        },
        {
          "1559": "33vw"
        },
        {
          "4999": "34vw"
        }
      ]
    },
    "cms-two-column-vertical-square": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680],
        "aspectRatio": "vertical-square"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "vertical-square"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "50vw"
        }
      ]
    },
    "cms-video": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000],
        "aspectRatio": "widescreen"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "widescreen"
      },
      "sizes": []
    },
    "cms-shop-the-look-hero": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680],
        "aspectRatio": "vertical"
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360],
        "aspectRatio": "vertical"
      },
      "sizes": [
        {
          "767": "100vw"
        },
        {
          "4999": "54vw"
        }
      ]
    },
    "cms-shop-the-look": {
      "desktop": {
        "srcSetWidths": [550,700,850,1100],
        "aspectRatio": "vertical"
      },
      "mobile": {
        "srcSetWidths": [300,400,550,700,850,1100],
        "aspectRatio": "vertical"
      },
      "sizes": [
        {
          "767": "50vw"
        },
        {
          "4999": "22vw"
        }
      ]
    },
    "cms-slider": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000,2600,3500]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360,1680,2000]
      },
      "sizes": [
        {
          "459": "130vw"
        },
        {
          "4999": "150vw"
        }
      ]
    },
    "cms-slider-double": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360,1680,2000]
      },
      "sizes": [
        {
          "459": "130vw"
        },
        {
          "767": "150vw"
        },
        {
          "4999": "100vw"
        }
      ]
    },
    "background": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360]
      },
      "sizes": []
    },
    "background-xl": {
      "desktop": {
        "srcSetWidths": [850,1100,1360,1680,2000,2600,3500]
      },
      "mobile": {
        "srcSetWidths": [400,550,700,850,1100,1360,1680,2000]
      },
      "sizes": []
    }
  }
};
